import { useMemo } from 'react'
import { ASvg, MComparisonTable } from 'ui'
import SvgCheckIcon from 'ui/svg/icons/fill/check.svg'
import SvgCloseIcon from 'ui/svg/icons/stroke/close.svg'
import './c-comparison-table.css'
import classNames from 'classnames'
import { useRouter } from 'next/router'

export type CComparisonTableBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  heading?: string
  headingUsSite?: string
  homepageHeading?: string
  copy?: string
  copyUs?: string
  disableColumns?: Array<string>
  tableHeader?: Array<{
    mainHeading?: string
    columnHeading1?: string
    columnHeading2?: string
    columnHeading3?: string
  }>
  tableBody?: Array<{
    rowHeading?: string
    data1?: string
    data1Asterisks?: string
    data1Superscript?: string
    data2?: string
    data2Asterisks?: string
    data2Superscript?: string
    data3?: string
    data3Asterisks?: string
    data3Superscript?: string
  }>
  tableFooter?: Array<{
    mainNote?: string
    columnNote1?: string
    columnNote2?: string
    columnNote3?: string
  }>
}

type Props = {
  field: Array<CComparisonTableBlock>
  isUs?: boolean
}

export const CComparisonTable = ({ field, isUs }: Props) => {
  const router = useRouter()
  const isHomepage = Boolean(router.asPath === '/')
  const { content, mainBlock, firstHeader } = useMemo(() => {
    const content = field && field.length ? field : []
    const mainBlock = content.find((item) => item.typeHandle === 'main')
    const firstHeader = content.find(
      (item) => item.typeHandle === 'tableHeader'
    )

    return { content, mainBlock, firstHeader }
  }, [field])

  if (!content.length) {
    return <></>
  }

  const altStyles = Boolean(isHomepage && isUs)

  return (
    <section
      className={classNames('c-comparison-table px-5', {
        'py-20 ': !altStyles,
        'py-5': altStyles,
      })}
    >
      <div className="anchor" id="comparison" />
      {mainBlock && (
        <>
          {isHomepage && isUs ? (
            <div>
              {mainBlock.homepageHeading && (
                <h2 className="text-center text-neutral-dark-gray text-3xl mb-[40px] md:mb-[50px] md:text-heading whitespace-pre-line">
                  {mainBlock.homepageHeading}
                </h2>
              )}
            </div>
          ) : (
            <>
              {isUs ? (
                <div>
                  {mainBlock.headingUsSite && (
                    <h2
                      className="text-neutral-dark-gray text-h5 whitespace-pre-line"
                      dangerouslySetInnerHTML={{
                        __html: mainBlock.headingUsSite,
                      }}
                    />
                  )}
                  {mainBlock.copyUs && (
                    <div
                      className={classNames(
                        'pie-8 mt-4 mb-6 text-neutral-dark-gray font-normal sm:mb-20 s-wysiwyg',
                        {
                          'max-w-lg text-p': !isUs,
                          'max-w-[700px] text-lg text-gray-500': isUs,
                        }
                      )}
                      dangerouslySetInnerHTML={{ __html: mainBlock.copyUs }}
                    />
                  )}
                </div>
              ) : (
                <div>
                  {mainBlock.heading && (
                    <h2
                      className="text-neutral-dark-gray text-h5 whitespace-pre-line"
                      dangerouslySetInnerHTML={{ __html: mainBlock.heading }}
                    />
                  )}
                  {mainBlock.copy && (
                    <div
                      className={classNames(
                        'pie-8 mt-4 mb-6 text-neutral-dark-gray font-normal sm:mb-20 s-wysiwyg',
                        {
                          'max-w-lg text-p': !isUs,
                          'max-w-[700px] text-lg text-gray-500': isUs,
                        }
                      )}
                      dangerouslySetInnerHTML={{ __html: mainBlock.copy }}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
      {isUs ? (
        <MComparisonTable
          firstHeader={firstHeader}
          content={content}
          mainBlock={mainBlock}
        />
      ) : (
        <>
          {firstHeader?.tableHeader && (
            <div className="c-comparison-table_custom-stick bg-fixed bg-neutral-white">
              <div className="flex justify-around w-full sm:w-1/2 text-neutral-dark-gray">
                {!mainBlock?.disableColumns?.includes('1') && (
                  <h4 className="w-20 text-center text-h6 text">
                    {firstHeader.tableHeader[0].columnHeading1}
                  </h4>
                )}
                {!mainBlock?.disableColumns?.includes('2') && (
                  <h4 className="w-20 text-center text-h6 text">
                    {firstHeader.tableHeader[0].columnHeading2}
                  </h4>
                )}
                {!mainBlock?.disableColumns?.includes('3') && (
                  <h4 className="w-20 text-center text-h6 text">
                    {firstHeader.tableHeader[0].columnHeading3}
                  </h4>
                )}
              </div>
            </div>
          )}
          {content.map((row, index) => (
            <div
              id={row.idName}
              key={`second${index}`}
              className={`
            text-neutral-dark-gray
            ${row.typeHandle === 'main' ? '' : 'c-comparison-table__row'}
          `}
            >
              {row.typeHandle === 'tableHeader' && row.tableHeader && (
                <div className="flex w-full sm:mt-16 c-comparison-table_custom-stick">
                  <h3 className="hidden w-full text-start text-h6 text sm:w-1/2 sm:block">
                    {row.tableHeader[0].mainHeading}
                  </h3>
                  <div className="c-comparison-table__row-headers justify-around w-full sm:w-1/2">
                    {!mainBlock?.disableColumns?.includes('1') && (
                      <h4 className="w-20 text-center text-h6 text">
                        {row.tableHeader[0].columnHeading1}
                      </h4>
                    )}
                    {!mainBlock?.disableColumns?.includes('2') && (
                      <h4 className="w-20 text-center text-h6 text">
                        {row.tableHeader[0].columnHeading2}
                      </h4>
                    )}
                    {!mainBlock?.disableColumns?.includes('3') && (
                      <h4 className="w-20 text-center text-h6 text">
                        {row.tableHeader[0].columnHeading3}
                      </h4>
                    )}
                  </div>
                </div>
              )}
              {row.typeHandle === 'tableBody' && row.tableBody && (
                <div
                  className={`
                flex flex-col w-full c-comparison-table__row-table-body h-28 sm:h-16 sm:flex-row
                ${
                  content[index + 1] &&
                  content[index + 1].typeHandle !== 'tableFooter'
                    ? 'sm:border-b sm:border-neutral-gray'
                    : ''
                }
              `}
                >
                  <p className="w-full font-bold text-center uppercase border-t border-b c-comparison-table__row-heading sm:pt-6 sm:pb-0 text-button sm:text-start sm:w-1/2 border-neutral-gray sm:border-0">
                    {row.tableBody[0].rowHeading}
                  </p>
                  <div className="flex justify-around w-full text-center sm:w-1/2">
                    {!mainBlock?.disableColumns?.includes('1') && (
                      <div className="w-20 c-comparison-table__row-svg-cont relative">
                        {!row.tableBody[0].data1 && (
                          <ASvg
                            aria-hidden="true"
                            className="w-10 h-10 stroke-current silver-chalice"
                            svg={SvgCloseIcon}
                          />
                        )}
                        {!!row.tableBody[0].data1 && (
                          <ASvg
                            aria-hidden="true"
                            className="w-10 h-10 fill-current text-primary-teal"
                            svg={SvgCheckIcon}
                          />
                        )}
                        {Boolean(row.tableBody[0].data1Asterisks) && (
                          <span className="!absolute left-[5.5rem] top-[10px] text-xs">
                            {row.tableBody[0].data1Asterisks}
                          </span>
                        )}
                        {Boolean(row.tableBody[0].data1Superscript) && (
                          <span className="!absolute left-[5.5rem] top-[10px] text-xs">
                            {row.tableBody[0].data1Superscript}
                          </span>
                        )}
                      </div>
                    )}
                    {!mainBlock?.disableColumns?.includes('2') && (
                      <div className="w-20 c-comparison-table__row-svg-cont relative">
                        {!row.tableBody[0].data2 && (
                          <ASvg
                            aria-hidden="true"
                            className="w-10 h-10 stroke-current silver-chalice"
                            svg={SvgCloseIcon}
                          />
                        )}
                        {!!row.tableBody[0].data2 && (
                          <ASvg
                            aria-hidden="true"
                            className="w-10 h-10 fill-current text-primary-teal"
                            svg={SvgCheckIcon}
                          />
                        )}
                        {Boolean(row.tableBody[0].data2Asterisks) && (
                          <span className="!absolute left-[5.5rem] top-[10px] text-xs">
                            {row.tableBody[0].data2Asterisks}
                          </span>
                        )}
                        {Boolean(row.tableBody[0].data2Superscript) && (
                          <span className="!absolute left-[5.5rem] top-[10px] text-xs">
                            {row.tableBody[0].data2Superscript}
                          </span>
                        )}
                      </div>
                    )}
                    {!mainBlock?.disableColumns?.includes('3') && (
                      <div className="w-20 c-comparison-table__row-svg-cont relative">
                        {!row.tableBody[0].data3 && (
                          <ASvg
                            aria-hidden="true"
                            className="w-10 h-10 stroke-current silver-chalice"
                            svg={SvgCloseIcon}
                          />
                        )}
                        {!!row.tableBody[0].data3 && (
                          <ASvg
                            aria-hidden="true"
                            className="w-10 h-10 fill-current text-primary-teal"
                            svg={SvgCheckIcon}
                          />
                        )}
                        {Boolean(row.tableBody[0].data3Asterisks) && (
                          <span className="!absolute left-[5.5rem] top-[10px] text-xs">
                            {row.tableBody[0].data3Asterisks}
                          </span>
                        )}
                        {Boolean(row.tableBody[0].data3Superscript) && (
                          <span className="!absolute left-[5.5rem] top-[10px] text-xs">
                            {row.tableBody[0].data3Superscript}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {row.typeHandle === 'tableFooter' && row.tableFooter && (
                <div
                  className={`
                flex flex-col w-full sm:flex-row
                ${
                  content[index + 1] &&
                  content[index + 1].typeHandle === 'tableHeader'
                    ? ''
                    : 'sm:border-b sm:border-neutral-gray'
                }
              `}
                >
                  <div className="w-full sm:w-1/2">
                    {row.tableFooter[0].mainNote}
                  </div>
                  <div className="flex justify-around w-full pb-2 text-primary-gray text-center sm:w-1/2 sm:pb-4">
                    {!mainBlock?.disableColumns?.includes('1') && (
                      <p
                        className="c-comparison-table__footer-p px-2 lg:px-0"
                        dangerouslySetInnerHTML={{
                          __html: row.tableFooter[0].columnNote1 || '',
                        }}
                      />
                    )}
                    {!mainBlock?.disableColumns?.includes('2') && (
                      <p
                        className="c-comparison-table__footer-p px-2 lg:px-0"
                        dangerouslySetInnerHTML={{
                          __html: row.tableFooter[0].columnNote2 || '',
                        }}
                      />
                    )}
                    {!mainBlock?.disableColumns?.includes('3') && (
                      <p
                        className="c-comparison-table__footer-p px-2 lg:px-0"
                        dangerouslySetInnerHTML={{
                          __html: row.tableFooter[0].columnNote3 || '',
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
          <div className="mt-10 text-xs">
            <p>UV: ultraviolet.</p>
            <p>
              * There is a limit flexibility if future procedures involve the
              cornea.
            </p>
            <p>** Not applicable</p>
            <p>*** Limited</p>
          </div>
        </>
      )}
    </section>
  )
}

export const CComparisonTableFragments = {
  field: `
    fragment comparisonTable on comparisonTable_MatrixField {
      ... on comparisonTable_main_BlockType {
        id
        typeHandle
        idName
        heading
        headingUsSite
        homepageHeading
        copy
        copyUs
        disableColumns
      }
      ... on comparisonTable_tableHeader_BlockType {
        id
        typeHandle
        tableHeader {
          mainHeading
          columnHeading1
          columnHeading2
          columnHeading3
        }
      }
      ... on comparisonTable_tableBody_BlockType {
        id
        typeHandle
        tableBody {
          rowHeading
          data1
          data2
          data3
          data1Asterisks
          data2Asterisks
          data3Asterisks
          data1Superscript
          data2Superscript
          data3Superscript
        }
      }
      ... on comparisonTable_tableFooter_BlockType {
        id
        typeHandle
        tableFooter {
          mainNote
          columnNote1
          columnNote2
          columnNote3
        }
      }
    }
  `,
}
