import { CraftAssetField, CraftLinkItField } from 'tsconfig/craft-types'
import Image from 'next/image'
import { AButton } from '../atoms/a-button'
import throttle from 'lodash/throttle'
import { useEffect, useRef } from 'react'
import { FormData, MFormSearch } from '../molecules/m-form-search'
import { ASvg } from '../atoms/a-svg'
import SvgSearchIcon from '../../svg/icons/stroke/search.svg'
import TagManager from "react-gtm-module"
import { useSiteInfo } from "shared"
import { useTranslation } from 'next-i18next'
import './c-steps.css'

interface Step {
  stepTitle?: string
  stepContent?: string
  image?: Array<CraftAssetField>
}
export interface CStepsBlock {
  heading?: string
  subHeading?: string
  steps?: Step[]
  ctaSection?: Array<{
    sectionTitle?: string
    cta?: CraftLinkItField
  }>
}

interface CStepsProps {
  field: Array<CStepsBlock>
}

export const CSteps = ({ field }: CStepsProps) => {
  const siteInfo = useSiteInfo()
  const { t } = useTranslation('common')
  const { steps, heading, ctaSection, subHeading } = field?.[0] ?? []

  const componentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const isMobile = () => window.innerWidth <= 768

    const handleScroll = throttle(() => {
      if (!isMobile()) return

      const stickyCTA = document.querySelector('#sticky-cta') as HTMLElement
      if (!stickyCTA || !componentRef.current) return

      const componentRect = componentRef.current.getBoundingClientRect()
      const componentTopPosition = componentRect.top + window.pageYOffset
      const componentBottomPosition = componentRect.bottom
      const viewportHeight = window.innerHeight
      const topThreshold = 0
      const bottomThreshold = viewportHeight * 0.9

      const isTopInView =
        componentTopPosition <= window.pageYOffset + topThreshold
      const isBottomAboveThreshold = componentBottomPosition > bottomThreshold

      if (isTopInView && isBottomAboveThreshold) {
        stickyCTA.className =
          'fixed bg-gray-100 bottom-0 left-0 right-0 p-[24px]'
      } else {
        stickyCTA.className = 'static bg-white p-0 mt-[25px]'
      }
    }, 100)

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const submitDoctorSearch = (data: FormData) => {
    const piiRegex = /(\S+@\S+)/g;

    if (piiRegex.test(data.q)) {
      console.log(
        "Refusing to search for something that looks like personal information."
      );
      return;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: "doctorSearch",
        event_category: "Doctor Search",
        event_action: "Find a Doctor component before footer",
        event_label: data.q,
      },
    });

    window.location.href = `${siteInfo.doctorUrl}?q=${encodeURIComponent(
      data.q
    )}`;
  };

  return (
    <div className="md:px-18 my-10">
      <div className="o-container gap-6 lg:gap-16 o-container--2xl items-center">
        <div ref={componentRef} className="md:flex gap-5 flex-row justify-between flex-row-reverse lg:mt-0">
          {Boolean(ctaSection?.length) && (
            <div id="sticky-cta">
              <div className="md:sticky md:top-[150px] mt-14 mb-14 md:mt-0 md:mb-20">
                {ctaSection?.[0]?.sectionTitle && (
                  <h4 className="text-3xl text-primary-dark-teal font-semibold text-center mb-[16px]">
                    {ctaSection?.[0]?.sectionTitle}
                  </h4>
                )}
                {ctaSection?.[0]?.cta?.url && (
                  <AButton
                    className="w-full lg:w-[350px]"
                    type={ctaSection?.[0]?.cta?.type}
                    href={ctaSection?.[0]?.cta?.url}
                    rel={ctaSection?.[0]?.cta?.type === 'url' ? 'noreferrer' : ''}
                    target={ctaSection?.[0]?.cta?.target}
                    size="lg"
                  >
                    <span>{ctaSection?.[0]?.cta?.text}</span>
                  </AButton>
                )}
                {!ctaSection?.[0]?.cta?.url && (
                  <div className="flex-grow w-full rounded-full md:bg-transparent text-primary-dark-teal">
                    <MFormSearch
                      icon={
                        <ASvg
                          aria-label="Search"
                          className="stroke-current w-8 h-8"
                          svg={SvgSearchIcon}
                        />
                      }
                      text={'Enter Your Zip Code'}
                      type="text"
                      onSubmit={submitDoctorSearch}
                      className="md:text-lg"
                    />
                  </div>
                )}
              
              </div>
            </div>
          )}
          <div>
            {heading && (
              <h3 className="text-4xl md:text-heading mb-5 text-black">
                {heading}
              </h3>
            )}
            {subHeading && <h5 className="text-xl md:text-2xl font-light">{subHeading}</h5>}
          </div>
        </div>
        
        {Boolean(steps?.length) && (
          <div className="flex flex-wrap mb-16 gap-[5%]">
            {steps?.map((step, i) => {
              return (
                <>
                  <div className="lg:flex md:flex w-[100%] pt-11 pb-10 md:pb-0 md:border-b-0 hidden md:inline-block steps-card">
                      <div className="lg:hidden md:hidden md:flex-1 text-2xl uppercase font-semibold text-primary-dark-teal flex justify-between items-center">
                          <span>Step {i + 1}</span>
                      </div>
                      {step.image?.[0]?.url && (
                        <div className="md:mr-6 hidden md:inline-block steps-icon">
                          <Image
                            className="h-[56px] w-[56px] md:h-[70px] md:w-[70px]  lg:mr-[30px]"
                            src={step.image?.[0]?.url}
                            alt={step.image?.[0]?.title ?? ''}
                            height={step.image?.[0]?.height}
                            width={step.image?.[0]?.width}
                          />
                        </div>
                      )}
                      <div className="flex-1">
                          <div className="text-2xl md:text-xl font-semibold mb-0 md:mb-5 mt-5 lg:mt-0 text-black step-title">
                              <span className="hidden md:inline-block mr-2.5">{`${i + 1}.`}</span>
                              {step.stepTitle}
                          </div>
                          <div className="text-lg text-gray-850 dotted-box">
                            {step.stepContent && (
                              <div
                                className="text-lg text-gray-850 [&>p>a]:underline pt-5"
                                dangerouslySetInnerHTML={{ __html: step.stepContent }}
                              />
                            )}
                          </div>
                      </div>
                  </div>
                  <div className="block md:w-[50%] w-[100%] pt-11 pb-10 md:pb-0 md:pt-20 md:border-b-0 md:hidden dotted-line">
                    <div className="lg:hidden md:hidden md:flex-1 text-2xl uppercase font-semibold text-primary-dark-teal flex justify-between items-center">
                        <span>Step {i + 1}</span>
                        {step.image?.[0]?.url && (
                          <div className="w-14 h-14 md:mr-6 md:hidden">
                            <Image
                              className="h-[56px] w-[56px] md:h-[70px] md:w-[70px] lg:mr-[30px]"
                              src={step.image?.[0]?.url}
                              alt={step.image?.[0]?.title ?? ''}
                              height={step.image?.[0]?.height}
                              width={step.image?.[0]?.width}
                            />
                          </div>
                        )}
                    </div>
                    <div>
                      <div className="text-2xl md:text-xl font-semibold mb-0 md:mb-5 mt-5 lg:mt-0 text-black">
                        <span className="hidden md:inline-block mr-2.5">{`${i + 1}.`}</span>
                        {step.stepTitle}
                      </div>
                      <div className="text-lg text-gray-850 pt-5 md:border-t-2 md:border-dotted steps-border">
                        {step.stepContent && (
                          <div
                            className="text-lg text-gray-850 [&>p>a]:underline pt-5"
                            dangerouslySetInnerHTML={{ __html: step.stepContent }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export const CStepsFragment = `
  fragment steps on steps_MatrixField {
    ... on steps_block_BlockType {
      heading
      subHeading
      steps {
        ... on steps_BlockType {
          stepTitle
          stepContent
          image {
            url @transform(mode: "crop", width: 90, immediately: true)
            title
            width
            height
          }
        }
      }
      ctaSection {
        ... on ctaSection_BlockType {
          sectionTitle
          cta {
            type
            url
            text
            target
          }
        }
      }
    }
  }
`
