import { useEffect, useState } from 'react'
import { CraftAssetField } from 'tsconfig/craft-types'
import useMediaQuery from 'doctors/hooks/use-media-query'

type Props = {
  className?: string
  imageDesktop?: CraftAssetField | null
  imageMobile?: CraftAssetField | null
  mediaQuery?: string
}

const getObjectPosition = (
  image?: CraftAssetField | null
): string | undefined => {
  if (!image?.hasFocalPoint) return

  return image.focalPoint?.map((v) => `${v * 100}%`).join(' ')
}

export const APicture = ({
  className,
  imageDesktop,
  imageMobile,
  mediaQuery = '(max-width: 640px)',
}: Props) => {
  const isMobile = useMediaQuery(mediaQuery)

  const [objectPosition, setObjectPosition] = useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    setObjectPosition(
      getObjectPosition(isMobile && imageMobile ? imageMobile : imageDesktop)
    )
  }, [imageDesktop, imageMobile, isMobile])

  if (!imageDesktop?.url) return <></>

  return (
    <picture className='a-picture flex h-full'>
      {!!imageMobile?.url && (
        <source media={mediaQuery} srcSet={imageMobile.url} style={{ objectPosition }} />
      )}
      <img
        className={className}
        src={isMobile ? imageMobile?.url ?? imageDesktop.url : imageDesktop.url}
        alt={imageDesktop.title}
        style={{ objectPosition }}
      />
    </picture>
  )
}
